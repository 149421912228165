import React, { FC } from 'react';
import cx from 'classnames';
import * as SC from './styled';
export type SampleProps = {
  className?: string;
  color?: string;
};
const Loader: FC<SampleProps> = props => {
  const {
    className,
    color
  } = props;
  return <SC.Container className={cx('Loader', className)} $color={color}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </SC.Container>;
};
export default Loader;