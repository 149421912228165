import { FC, useState } from 'react';
import { PictureProps } from '../../atoms/Picture';
import { ActionButtonProps } from '../../atoms/ActionButton';
import { Icons, IconType } from '../../atoms/Icon';
import { ActionButtonVariant } from '../../atoms/ActionButton/styled';
import * as SC from './styled';
export type CouponCardProps = {
  className?: string;
  brandLogoProps?: PictureProps;
  imageProps: PictureProps;
  title?: string;
  amount?: string;
  shortDescription?: string;
  helpText?: string;
  helpBoldText?: string;
  actionButtonProps?: ActionButtonProps;
  labelProps?: {
    label: string;
    icon?: IconType;
  };
  addedProps?: {
    label: string;
    actionButtonProps: ActionButtonProps;
  };
  usedLabel?: string;
  outLabel?: string;
  quantityLabel?: string;
};
const CouponCard: FC<CouponCardProps> = ({
  className,
  imageProps,
  brandLogoProps,
  title,
  amount,
  shortDescription,
  helpText,
  helpBoldText,
  actionButtonProps,
  labelProps,
  addedProps,
  usedLabel,
  outLabel,
  quantityLabel
}) => {
  const [helpOpen, setHelpOpen] = useState(false);
  return <SC.Container className={className}>
      <SC.Card>
        <SC.Head>
          {brandLogoProps && <SC.Logo {...brandLogoProps} />}
          <SC.HeadContent>
            {labelProps && <SC.Label>
                {labelProps.icon && <SC.LabelIcon icon={labelProps.icon} />}
                <span>{labelProps.label}</span>
              </SC.Label>}
            <SC.Title>{title}</SC.Title>
          </SC.HeadContent>
          <SC.HelpButton onClick={() => setHelpOpen(true)}>
            <SC.HelpIcon icon={Icons.help} />
          </SC.HelpButton>
        </SC.Head>
        <SC.Main>
          <SC.LeftContent>
            {imageProps && <SC.ProductImage {...imageProps} />}
            {quantityLabel && <SC.QuantityLabel>{quantityLabel}</SC.QuantityLabel>}
          </SC.LeftContent>
          <SC.Content>
            {amount && <SC.Amount>
                {amount}
                <sup>€</sup>
              </SC.Amount>}
            {shortDescription && <SC.ShortDescription>{shortDescription}</SC.ShortDescription>}
            {actionButtonProps && <SC.Button {...actionButtonProps} $disabled={actionButtonProps.disabled} />}
          </SC.Content>
        </SC.Main>
        {(helpOpen || usedLabel || outLabel) && <SC.WhiteOverlay $readable={helpOpen ? true : false} />}
        {helpOpen && <SC.Help>
            <SC.HelpButton onClick={() => setHelpOpen(false)}>
              <SC.HelpIcon icon={Icons.close} />
            </SC.HelpButton>
            <SC.HelpText>{helpText}</SC.HelpText>
            <SC.HelpBoldText>{helpBoldText}</SC.HelpBoldText>
          </SC.Help>}
        {usedLabel && <SC.UsedLabel>{usedLabel}</SC.UsedLabel>}
        {outLabel && <SC.OutLabel>{outLabel}</SC.OutLabel>}
      </SC.Card>
      {addedProps && <>
          <SC.AddedOverlay />
          <SC.AddedLabel>{addedProps.label}</SC.AddedLabel>
          {addedProps.actionButtonProps && <SC.AddedButton {...addedProps.actionButtonProps} variant={ActionButtonVariant.yellow} />}
        </>}
    </SC.Container>;
};
export default CouponCard;